.home-wrapper {
    padding-top: 149px;
}

.sponsors-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 30px 0;
}

.sponsors-wrapper {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1px solid #454545;
    gap: 30px;
}

.sponsors-wrapper img {
    height: 40px;
}

.sponsors-wrapper .divider {
    height: 40px;
    width: 1px;
    background-color: #454545;
}

@media (max-width: 767px) {
    .home-wrapper {
        padding-top: 120px;
    }

    .sponsors-wrapper img {
        height: 20px; /* Adjust this value for mobile */
    }
    .sponsors-wrapper .divider {
        height: 20px;
    }
}
